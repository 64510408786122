
import {ref, defineComponent, reactive, toRefs, onBeforeMount, onMounted} from 'vue'
import {ElMessageBox} from 'element-plus';
import {ElLoading} from 'element-plus';
import axios from '@/api/http.js';
// import country from "@/commonData/country.js"
import {useRouter} from "vue-router";
import footBar from "@/components/nav/inpixon/footBar.vue";


export default defineComponent({
  name: '',
  components: {
    footBar
  },
  setup() {
    const router = useRouter();
    // console.log("==== country=====", country)
    const registerFormRef = ref(null);
    const checkAge = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error('This field is required'))
      } else {
        if (isNaN(value)) {
          callback(new Error('Number only'))
        } else {
          callback();
        }
      }
    };
    const data = reactive({
      // form: {
      //   email: "cheng.xu@inpixon.com",
      //   firstName: "Haocheng",
      //   lastName: "Xu",
      //   company: "zivix",
      //   title: "dev",
      //   isReceiveMaterials: "on",
      //   countryCode:"",
      //   countryRegion: "United States",
      //   relation: "Customer",
      //   phone: 13727038899,
      //   city: 'Zhu Hai',
      //   address: 'V12',
      //   zipcode: 123456,
      //   size: "large",
      //   countryOptions: [
      //     // {label: "China", value: "China"},
      //     // {label: "Japan", value: "Japan"},
      //     // {label: "Korea", value: "Korea"},
      //     // {label: "United States", value: "United States"},
      //     // {label: "England", value: "England"},
      //   ],
      //   countryCodeOptions:[],
      //   relationOptions: [
      //     {label: "Partner", value: "Partner"},
      //     {label: "Distributor", value: "Distributor"},
      //     {label: "Employee", value: "Employee"},
      //     {label: "Customer", value: "Customer"},
      //   ],
      //   sizeOptions: [
      //     {label: "Small", value: "Small"},
      //     {label: "Medium", value: "medium"},
      //     {label: "Large", value: "large"},
      //   ],
      //
      // },
      form: {
        firstName: "",
        lastName: "",
        company: "",
        title: "",
        email: "",
        phone: "",
        isReceiveMaterials: "on",
        countryRegion: "",
        countryCode: "",
        city: "",
        relation: "",
        address: "",
        zipcode: "",
        size: "",
        countryOptions: [
          // {label: "China", value: "China"},
          // {label: "Japan", value: "Japan"},
          // {label: "Korea", value: "Korea"},
          // {label: "United States", value: "United States"},
          // {label: "England", value: "England"},
        ],
        countryCodeOptions: [],
        relationOptions: [
          {label: "Partner", value: "Partner"},
          {label: "Distributor", value: "Distributor"},
          {label: "Employee", value: "Employee"},
          {label: "Customer", value: "Customer"},
        ],
        cityOptions: [],
        sizeOptions: [
          {label: "Small", value: "Small"},
          {label: "Medium", value: "Medium"},
          {label: "Large", value: "Large"},
          {label: "X-Large", value: "X-Large"},
          {label: "XX-Large", value: "XX-Large"},
        ],
        password: "",
        retypePassword: "",
      },
      rules: {
        firstName: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        lastName: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        email: [
          {required: true, message: 'This field is required', trigger: 'blur'},
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],

        company: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        title: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        countryRegion: [
          {required: true, message: 'This field is required', trigger: 'change'},
        ],
        countryCode: [
          {required: true, message: 'This field is required', trigger: 'change'},
        ],
        relation: [
          {required: true, message: 'This field is required', trigger: 'change'},
        ],
        // phone: [
        //   {required: true, message: 'This field is required', trigger: 'blur'},
        //   {pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: '手机号格式不对', trigger: 'blur'},
        // ],
        phone: [
          {required: true, message: 'This field is required', trigger: 'blur'},
          // {required: true, validator: checkAge, trigger: 'blur'},
        ],
        city: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        address: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        zipcode: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        size: [
          {required: true, message: 'This field is required', trigger: 'change'},
        ],
      }
    })
    const refData = toRefs(data);
    const clickSubmit = () => {
      registerFormRef.value.validate((valid) => {
        if (valid) {
          const loading = ElLoading.service({
            lock: true,
          })

          axios.request({
            method: 'GET',
            url: '/files/whitelist_SKO_2022.json',
          }).then(res => {
            console.log('res:', res);
            let whiteList = res.data;
            let whiteListLower = [];
            whiteList.map(item=>{
              item = item.toLowerCase();
              whiteListLower.push(item);
            })
            let lowerEmail = data.form.email.toLowerCase();
            let condition = whiteListLower.includes(lowerEmail);
            if (condition) {
              axios.request({
                method: 'POST',
                url: '/cms/addSpecialAttendee',
                data: {
                  email: data.form.email,
                  firstName: data.form.firstName,
                  lastName: data.form.lastName,
                  company: data.form.company,
                  title: data.form.title,
                  // isReceiveMaterials: data.form.isReceiveMaterials,
                  countryRegion: data.form.countryRegion,
                  phone: String(data.form.phone),
                  city: data.form.city,
                  address: data.form.address,
                  zipcode: String(data.form.zipcode),
                  size: data.form.size,
                  countryCode: data.form.countryCode
                  // relation: data.form.relation,
                },
              }).then(res => {
                console.log('res:', res);
                console.log('text:', res.data.data.text);
                ElMessageBox.alert(res.data.data.text, '', {
                  confirmButtonText: 'OK',
                  callback: () => {
                    router.push({name: 'Login'});
                  }
                })
                loading.close();
              }).catch(error => {
                loading.close();
                if (error.response) {
                  console.log("==== error.response=====", error.response)
                  ElMessageBox.alert(error.response.data.error, '', {
                    confirmButtonText: 'OK',
                  })
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log("error.request:", error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error!!', error.message);
                }
                // console.log("error.config:",error.config);
              })
            } else {
              loading.close();
              ElMessageBox.alert('The Inpixon Sales Kickoff is only open to Inpixon\'s primary sales team. If you have any questions or believe you should have access please contact your manager.', '', {
                confirmButtonText: 'OK',
              })
            }
          }).catch(err => {
            loading.close();
            console.log(err);
          })
        } else {
          return false
        }
      })
    }
    // const initCountry = () => {
    //   let arr = [];
    //   country.map(item => {
    //     let obj = {};
    //     obj["label"] = item;
    //     obj["value"] = item;
    //     arr.push(obj);
    //   })
    //   data.form.countryOptions = arr;
    // }
    // initCountry();
    const countryCodes = () => {
      axios.request({
        method: 'GET',
        url: '/cms/countryCodes ',
      }).then(res => {
        console.log('countryCodes:', res.data);
        let countryCodes = res.data;
        let arrCountryCode = [];
        let arrCountry = [];
        countryCodes.map(item => {
          let obj = {};
          let obj2 = {};
          obj["label"] = item.country;
          obj["value"] = item.country;
          obj2["label"] = item.country + ' (' + item.code + ')';
          obj2["value"] = item.id;
          arrCountry.push(obj);
          arrCountryCode.push(obj2);
        })
        data.form.countryCodeOptions = arrCountryCode;
        data.form.countryOptions = arrCountry;
      }).catch(err => {
        console.log(err);
      })
    }
    countryCodes();
    const goContactSup = (link) => {
      window.open("https://usersupport.inpixon.com/hc/en-us/requests/new?ticket_form_id=1900000368487&tf_1900002231027=InpixonSKO")
    }
    return {
      ...refData,
      clickSubmit,
      registerFormRef,
      goContactSup
    }

  }
});
